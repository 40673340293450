<template>
  <div id="app">
    <b-navbar toggleable class="navbarSPL" :sticky="true">
      <b-navbar-nav class="w-100">
        <b-container class="bv-example-row" fluid>
          <b-row>
            <b-col cols="10" sm="10" md="10" lg="10" xl="5">
              <b-nav-text class="brandSPL">Swiss Post Labels</b-nav-text>
            </b-col>
            <b-col v-if="!mobileMenu" cols="0" lg="5">
              <b-nav>
                <b-nav-item
                  style="color: black"
                  :active="true"
                  @click="scrollMeTo('vorteile')"
                  >Vorteile</b-nav-item
                >
                <b-nav-item
                  style="color: black"
                  :active="true"
                  @click="scrollMeTo('gallerie')"
                  >Gallerie</b-nav-item
                >
                <b-nav-item
                  style="color: black"
                  :active="true"
                  @click="scrollMeTo('preise')"
                  >Preise</b-nav-item
                >
                <b-nav-item
                  style="color: black"
                  :active="true"
                  @click="scrollMeTo('integrationen')"
                  >Integrationen</b-nav-item
                >
              </b-nav>
            </b-col>
            <b-col v-if="!mobileMenu" cols="2" lg="2">
              <b-button class="h-100 btnSPL">Kostenlos testen </b-button>
            </b-col>
            <b-col
              v-if="mobileMenu"
              class="d-flex justify-content-between"
              cols="2"
            >
              <b-button
                class="toggleSPL"
                :active="true"
                v-b-modal.modalMobileMenu
                @click="expanded = !expanded"
              >
                <b-icon v-if="expanded" icon="x-lg" rotate="90"></b-icon>
                <b-icon v-else icon="list"></b-icon
              ></b-button>
            </b-col>
            <div
              v-show="expanded"
              style="box-shadow: rgba(0, 0, 0, 0.2) 0px 7px 8px 0px"
            >
              <b-container class="text-right mb-3">
                <br />
                <b-row class="w-100 pb-1" @click="scrollMeTo('vorteile', true)">
                  <b-col cols="12" class="p-0"
                    ><b-button class="w-100 btnSPL"
                      ><b-icon icon="box-seam"></b-icon>&emsp;Vorteile
                    </b-button></b-col
                  >
                </b-row>
                <b-row class="w-100 pb-1" @click="scrollMeTo('gallerie', true)">
                  <b-col cols="12" class="p-0"
                    ><b-button class="w-100 btnSPL"
                      ><b-icon icon="images"></b-icon>&emsp;Gallerie
                    </b-button></b-col
                  >
                </b-row>
                <b-row class="w-100 pb-1" @click="scrollMeTo('preise', true)">
                  <b-col cols="12" class="p-0"
                    ><b-button class="w-100 btnSPL"
                      ><b-icon icon="tag"></b-icon>&emsp;Preise
                    </b-button></b-col
                  >
                </b-row>
                <b-row
                  class="w-100 pb-1 mb-2"
                  @click="scrollMeTo('integrationen', true)"
                >
                  <b-col cols="12" class="p-0"
                    ><b-button class="w-100 btnSPL"
                      ><b-icon icon="code"></b-icon>&emsp;Integrationen
                    </b-button></b-col
                  >
                </b-row>
                <b-row class="w-100">
                  <div
                    class="w-100 mb-2"
                    style="background-color: #f4b953; height: 2px"
                  ></div>
                  <b-button class="h-100 btnSPL">Kostenlos testen </b-button>
                </b-row>
              </b-container>
            </div>
          </b-row>
        </b-container>
      </b-navbar-nav>
    </b-navbar>
    <div class="w-100 pt-5 backgroundSPL">
      <b-container class="">
        <b-row align-items-center>
          <b-col xs="12" xl="6"
            ><h1>
              Verknüpft mit Ihrem ERP, Swiss Post Barcode Labels erstellen.
              <br />
              <b>Einfach und effizient.</b>
            </h1>
            <br />
            <b-container class="w-100 pb-3">
              <b-row>
                <b-col style="max-width: 2em" cols="1" class="p-0"
                  ><b-icon font-scale="1.5" icon="check"></b-icon
                ></b-col>
                <b-col cols="11" class="p-0"
                  >Daten für Hin- und Rücksende Barcodes aus ERP</b-col
                >
              </b-row>
              <b-row>
                <b-col style="max-width: 2em" cols="1" class="p-0"
                  ><b-icon font-scale="1.5" icon="check"></b-icon
                ></b-col>
                <b-col cols="11" class="p-0"
                  >Mehrere Barcodes gleichzeitig erstellen</b-col
                >
              </b-row>
              <b-row>
                <b-col style="max-width: 2em" cols="1" class="p-0"
                  ><b-icon font-scale="1.5" icon="check"></b-icon
                ></b-col>
                <b-col cols="11" class="p-0"
                  >Tracking automatisiert auf Auftrag oder Rechnung
                </b-col>
              </b-row>
              <b-row>
                <b-col style="max-width: 2em" cols="1" class="p-0"
                  ><b-icon font-scale="1.5" icon="check"></b-icon
                ></b-col>
                <b-col cols="11" class="p-0"
                  >Viel Zeit sparen und Fehler vorbeugen</b-col
                >
              </b-row>
              <b-row>
                <b-col style="max-width: 2em" cols="1" class="p-0"
                  ><b-icon font-scale="1.5" icon="check"></b-icon
                ></b-col>
                <b-col cols="11" class="p-0">5 Labels pro Monat Gratis</b-col>
              </b-row>
            </b-container>
            <b-container class="p-0">
              <b-row class="mb-5">
                <b-col cols="5" md="5" lg="5" class="h-100">
                  <b-button class="btnSPL w-100" @click="scrollMeTo('vorteile')"
                    >Alle Vorteile</b-button
                  >
                </b-col>
                <b-col cols="5" md="5" lg="5">
                  <b-button class="btnSPL2 w-100">Kostenlos testen</b-button>
                </b-col>
              </b-row></b-container
            >
          </b-col>
          <b-col cols="12" lg="10" xl="6">
            <b-img-lazy
              fluid-grow
              :blank="true"
              center
              src="./assets/index.jpg"
              rounded
              alt="Swiss Post Labels Logo"
            ></b-img-lazy>
          </b-col>
        </b-row>
      </b-container>
      <div style="height: 25px"></div>
    </div>
    <div>
      <div>
        <div>
          <svg
            style="width: 100%; transform: rotate(180deg)"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 144.54 17.34"
            preserveAspectRatio="none"
            fill="#fcde58"
          >
            <path
              style="border-bottom: 10px solid #fcde58"
              d="M144.54 17.34H0V0H144.54ZM0 0S34 6 72 6 144.54 0 144.54 0"
            ></path>
          </svg>
        </div>
      </div>
      <br />
      <b-container fluid>
        <b-row>
          <b-col cols="12">
            <h2 id="vorteile" ref="vorteile" class="text-center">
              Ihre Vorteile in der Übersicht
            </h2>
            <br />
            <p class="text-center">
              Zeit sparen und die Versandlabels der Post automatisiert mit den
              schon erfassten Daten aus dem ERP erstellen. Die Versandnummer
              automatisch als Position auf Auftrag oder Rechnung einfügen, und
              den Rücksendelabel auf einem eigenen entworfen Dokument
              ausgedruckt mitsenden. <br />
              <b>Mit nur paar wenige Klicks!</b>
            </p>
          </b-col>
        </b-row>
        <b-row
          ><b-col>
            <div class="h-100 p-3">
              <b-embed
                style="box-shadow: 0px 0px 10px 10px #fcde58"
                controls
                class="w-100 h-100"
                type="video"
                aspect="16by9"
                src="./assets/videos/spl.mp4"
                allowfullscreen
              ></b-embed></div></b-col
        ></b-row>
      </b-container>
      <br /><br />
      <div>
        <div>
          <svg
            style="width: 100%"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 144.54 17.34"
            preserveAspectRatio="none"
            fill="#fcde58"
          >
            <path
              style="border-bottom: 10px solid #fcde58"
              d="M144.54 17.34H0V0H144.54ZM0 0S34 6 72 6 144.54 0 144.54 0"
            ></path>
          </svg>
        </div>
      </div>
      <div class="backgroundSPL">
        <b-container fluid>
          <b-row>
            <b-col cols="12" md="6">
              <b-container class="w-100 pb-3">
                <b-row>
                  <b-col style="max-width: 2em" cols="1" class="p-0"
                    ><b-icon font-scale="1.5" icon="check"></b-icon
                  ></b-col>
                  <b-col cols="11" class="p-0"
                    >Daten für Hin- und Rücksende Barcodes aus ERP</b-col
                  >
                </b-row>
                <b-row>
                  <b-col style="max-width: 2em" cols="1" class="p-0"
                    ><b-icon font-scale="1.5" icon="check"></b-icon
                  ></b-col>
                  <b-col cols="11" class="p-0"
                    >Mehrere Barcodes gleichzeitig erstellen</b-col
                  >
                </b-row>
                <b-row>
                  <b-col style="max-width: 2em" cols="1" class="p-0"
                    ><b-icon font-scale="1.5" icon="check"></b-icon
                  ></b-col>
                  <b-col cols="11" class="p-0"
                    >Tracking automatisiert auf Auftrag oder Rechnung
                  </b-col>
                </b-row>
                <b-row>
                  <b-col style="max-width: 2em" cols="1" class="p-0"
                    ><b-icon font-scale="1.5" icon="check"></b-icon
                  ></b-col>
                  <b-col cols="11" class="p-0"
                    >Viel Zeit sparen und Fehler vorbeugen</b-col
                  >
                </b-row>
                <b-row>
                  <b-col style="max-width: 2em" cols="1" class="p-0"
                    ><b-icon font-scale="1.5" icon="check"></b-icon
                  ></b-col>
                  <b-col cols="11" class="p-0">5 Labels pro Monat Gratis</b-col>
                </b-row>
              </b-container></b-col
            >
            <b-col cols="12" md="6">
              <b-container class="w-100 pb-3">
                <b-row>
                  <b-col style="max-width: 2em" cols="1" class="p-0"
                    ><b-icon font-scale="1.5" icon="check"></b-icon
                  ></b-col>
                  <b-col cols="11" class="p-0"
                    >Daten für Hin- und Rücksende Barcodes aus ERP</b-col
                  >
                </b-row>
                <b-row>
                  <b-col style="max-width: 2em" cols="1" class="p-0"
                    ><b-icon font-scale="1.5" icon="check"></b-icon
                  ></b-col>
                  <b-col cols="11" class="p-0"
                    >Mehrere Barcodes gleichzeitig erstellen</b-col
                  >
                </b-row>
                <b-row>
                  <b-col style="max-width: 2em" cols="1" class="p-0"
                    ><b-icon font-scale="1.5" icon="check"></b-icon
                  ></b-col>
                  <b-col cols="11" class="p-0"
                    >Tracking automatisiert auf Auftrag oder Rechnung
                  </b-col>
                </b-row>
                <b-row>
                  <b-col style="max-width: 2em" cols="1" class="p-0"
                    ><b-icon font-scale="1.5" icon="check"></b-icon
                  ></b-col>
                  <b-col cols="11" class="p-0"
                    >Viel Zeit sparen und Fehler vorbeugen</b-col
                  >
                </b-row>
                <b-row>
                  <b-col style="max-width: 2em" cols="1" class="p-0"
                    ><b-icon font-scale="1.5" icon="check"></b-icon
                  ></b-col>
                  <b-col cols="11" class="p-0">5 Labels pro Monat Gratis</b-col>
                </b-row>
              </b-container></b-col
            >
          </b-row>
        </b-container>
      </div>
      <div>
        <div>
          <svg
            style="width: 100%; transform: rotate(180deg)"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 144.54 17.34"
            preserveAspectRatio="none"
            fill="#fcde58"
          >
            <path
              style="border-bottom: 10px solid #fcde58"
              d="M144.54 17.34H0V0H144.54ZM0 0S34 6 72 6 144.54 0 144.54 0"
            ></path>
          </svg>
        </div>
      </div>
      <br /><br />
      <div
        class="text-center mx-auto pb-4"
        style="max-width: 90%; min-height: 400px"
      >
        <h2 ref="gallerie" class="text-center pb-4">Gallerie</h2>

        <div id="slider">
          <div
            v-for="i in [currentIndex]"
            :key="i"
            style="box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2)"
          >
            <img class="w-100" :src="currentImg()" />
          </div>
          <br />
          <a class="prev" @click="prev">&#10094; Previous</a>
          <a class="next" @click="next">Next &#10095;</a>
        </div>
      </div>
    </div>
    <br />
    <div>
      <div>
        <svg
          style="width: 100%"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 144.54 17.34"
          preserveAspectRatio="none"
          fill="#fcde58"
        >
          <path
            style="border-bottom: 10px solid #fcde58"
            d="M144.54 17.34H0V0H144.54ZM0 0S34 6 72 6 144.54 0 144.54 0"
          ></path>
        </svg>
      </div>
    </div>
    <div class="w-100 backgroundSPL">
      <h2 ref="preise" class="text-center pb-5">Preise</h2>
      <b-container>
        <b-row>
          <b-col cols="12" sm="6" md="6" lg="3" class="pb-3">
            <b-card
              class="h-100"
              style="box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2)"
            >
              <b-card-title class="text-center pb-1">Gratis</b-card-title>
              <h6 class="text-center month">Zum ausprobieren</h6>
              <hr />
              <div style="height: 140px">
                <p class="text-center">
                  nur <span class="price">CHF 0.00</span
                  ><span class="month">/Monat</span>
                </p>
                <p class="priceExplanation text-center">
                  Monatlich 5 Labels kostenlos zum testen <br />
                  Alle Funktionen
                </p>
              </div>
              <hr />
              <p class="text-center"><b>1 Benutzer</b></p>
              <p class="text-center">
                <b><span class="price">5</span> Labels pro Monat</b>
              </p>
              <b-container>
                <b-row>
                  <b-col style="max-width: 2em" cols="1" class="p-0"
                    ><b-icon
                      font-scale="1.5"
                      icon="check"
                      style="color: green"
                    ></b-icon
                  ></b-col>
                  <b-col cols="11">Rücksendelabels (GAS)</b-col>
                </b-row>
                <b-row>
                  <b-col style="max-width: 2em" cols="1" class="p-0"
                    ><b-icon
                      font-scale="1.5"
                      icon="check"
                      style="color: green"
                    ></b-icon
                  ></b-col>
                  <b-col cols="11"
                    >Versandlabels automatisch auf Auftrag/Rechnung PDF</b-col
                  >
                </b-row>
                <b-row>
                  <b-col style="max-width: 2em" cols="1" class="p-0"
                    ><b-icon
                      font-scale="1.5"
                      icon="check"
                      style="color: green"
                    ></b-icon
                  ></b-col>
                  <b-col cols="11"
                    >Rücksendelabel automatisch auf eigenes PDF pro
                    Sprache</b-col
                  >
                </b-row>
                <b-row>
                  <b-col style="max-width: 2em" cols="1" class="p-0"
                    ><b-icon
                      font-scale="1.5"
                      icon="check"
                      style="color: green"
                    ></b-icon
                  ></b-col>
                  <b-col cols="11"
                    >Sendungsnummer und Sendungsverfolgung automatisch in
                    Auftrag oder Rechnung einfügen</b-col
                  >
                </b-row>
                <b-row>
                  <b-col style="max-width: 2em" cols="1" class="p-0"
                    ><b-icon
                      font-scale="1.5"
                      icon="check"
                      style="color: green"
                    ></b-icon
                  ></b-col>
                  <b-col cols="11"
                    >Sendungsnummer und Sendungsverfolgung automatisch in ERP
                    hinterlegen</b-col
                  >
                </b-row>
                <b-row>
                  <b-col style="max-width: 2em" cols="1" class="p-0"
                    ><b-icon
                      font-scale="1.5"
                      icon="check"
                      style="color: green"
                    ></b-icon
                  ></b-col>
                  <b-col cols="11"
                    >Sämtliche Schweizer Post Dienst- und
                    Zusatzleistungen</b-col
                  >
                </b-row>
              </b-container>
              <br />
              <p class="month">Zusatz <small>(optional je 100 CHF</small>)</p>
              <b-container>
                <b-row>
                  <b-col style="max-width: 2em" cols="1" class="p-0"
                    ><b-icon font-scale="1.5" icon="plus"></b-icon
                  ></b-col>
                  <b-col cols="11">Schulung</b-col>
                </b-row>
                <b-row>
                  <b-col style="max-width: 2em" cols="1" class="p-0"
                    ><b-icon font-scale="1.5" icon="plus"></b-icon
                  ></b-col>
                  <b-col cols="11">Einrichtung</b-col>
                </b-row>
              </b-container>
            </b-card>
          </b-col>
          <b-col cols="12" sm="6" md="6" lg="3" class="pb-3">
            <b-card
              class="h-100"
              style="box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2)"
            >
              <b-card-title class="text-center pb-1">Starter</b-card-title>
              <h6 class="text-center month">Das Einsteiger Abo</h6>
              <hr />
              <div style="height: 140px">
                <p class="text-center">
                  nur <span class="price">CHF 9.40</span
                  ><span class="month">/Monat¹</span>
                </p>
                <p class="priceExplanation text-center">
                  bei jährlicher Rechnung <br />(CHF 9.90/Monat bei monatlicher
                  Rechnung)
                </p>
              </div>
              <hr />
              <p class="text-center"><b>1 Benutzer</b></p>
              <p class="text-center">
                <b><span class="price">50</span> Labels pro Monat</b>
              </p>
              <b-container>
                <b-row>
                  <b-col style="max-width: 2em" cols="1" class="p-0"
                    ><b-icon
                      font-scale="1.5"
                      icon="check"
                      style="color: green"
                    ></b-icon
                  ></b-col>
                  <b-col cols="11">Rücksendelabels (GAS)</b-col>
                </b-row>
                <b-row>
                  <b-col style="max-width: 2em" cols="1" class="p-0"
                    ><b-icon
                      font-scale="1.5"
                      icon="check"
                      style="color: green"
                    ></b-icon
                  ></b-col>
                  <b-col cols="11"
                    >Versandlabels automatisch auf Auftrag/Rechnung PDF</b-col
                  >
                </b-row>
                <b-row>
                  <b-col style="max-width: 2em" cols="1" class="p-0"
                    ><b-icon
                      font-scale="1.5"
                      icon="check"
                      style="color: green"
                    ></b-icon
                  ></b-col>
                  <b-col cols="11"
                    >Rücksendelabel automatisch auf eigenes PDF pro
                    Sprache</b-col
                  >
                </b-row>
                <b-row>
                  <b-col style="max-width: 2em" cols="1" class="p-0"
                    ><b-icon
                      font-scale="1.5"
                      icon="check"
                      style="color: green"
                    ></b-icon
                  ></b-col>
                  <b-col cols="11"
                    >Sendungsnummer und Sendungsverfolgung automatisch in
                    Auftrag oder Rechnung einfügen</b-col
                  >
                </b-row>
                <b-row>
                  <b-col style="max-width: 2em" cols="1" class="p-0"
                    ><b-icon
                      font-scale="1.5"
                      icon="check"
                      style="color: green"
                    ></b-icon
                  ></b-col>
                  <b-col cols="11"
                    >Sendungsnummer und Sendungsverfolgung automatisch in ERP
                    hinterlegen</b-col
                  >
                </b-row>
                <b-row>
                  <b-col style="max-width: 2em" cols="1" class="p-0"
                    ><b-icon
                      font-scale="1.5"
                      icon="check"
                      style="color: green"
                    ></b-icon
                  ></b-col>
                  <b-col cols="11"
                    >Sämtliche Schweizer Post Dienst- und
                    Zusatzleistungen</b-col
                  >
                </b-row>
              </b-container>
              <br />
              <p class="month">Zusatz <small>(optional je 100 CHF</small>)</p>
              <b-container>
                <b-row>
                  <b-col style="max-width: 2em" cols="1" class="p-0"
                    ><b-icon font-scale="1.5" icon="plus"></b-icon
                  ></b-col>
                  <b-col cols="11">Schulung</b-col>
                </b-row>
                <b-row>
                  <b-col style="max-width: 2em" cols="1" class="p-0"
                    ><b-icon font-scale="1.5" icon="plus"></b-icon
                  ></b-col>
                  <b-col cols="11">Einrichtung</b-col>
                </b-row>
              </b-container>
            </b-card>
          </b-col>
          <b-col cols="12" sm="6" md="6" lg="3" class="pb-3">
            <b-card
              class="h-100"
              style="box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2)"
            >
              <b-card-title class="text-center pb-1">Pro</b-card-title>
              <h6 class="text-center month">Standard Paket</h6>
              <hr />
              <div style="height: 140px">
                <p class="text-center">
                  nur <span class="price">CHF 14.15</span
                  ><span class="month">/Monat¹</span>
                </p>
                <p class="priceExplanation text-center">
                  bei jährlicher Rechnung <br />(CHF 14.90/Monat bei monatlicher
                  Rechnung)
                </p>
              </div>
              <hr />
              <p class="text-center"><b>3 Benutzer</b></p>
              <p class="text-center">
                <b><span class="price">200</span> Labels pro Monat</b>
              </p>
              <b-container>
                <b-row>
                  <b-col style="max-width: 2em" cols="1" class="p-0"
                    ><b-icon
                      font-scale="1.5"
                      icon="check"
                      style="color: green"
                    ></b-icon
                  ></b-col>
                  <b-col cols="11">Rücksendelabels (GAS)</b-col>
                </b-row>
                <b-row>
                  <b-col style="max-width: 2em" cols="1" class="p-0"
                    ><b-icon
                      font-scale="1.5"
                      icon="check"
                      style="color: green"
                    ></b-icon
                  ></b-col>
                  <b-col cols="11"
                    >Versandlabels automatisch auf Auftrag/Rechnung PDF</b-col
                  >
                </b-row>
                <b-row>
                  <b-col style="max-width: 2em" cols="1" class="p-0"
                    ><b-icon
                      font-scale="1.5"
                      icon="check"
                      style="color: green"
                    ></b-icon
                  ></b-col>
                  <b-col cols="11"
                    >Rücksendelabel automatisch auf eigenes PDF pro
                    Sprache</b-col
                  >
                </b-row>
                <b-row>
                  <b-col style="max-width: 2em" cols="1" class="p-0"
                    ><b-icon
                      font-scale="1.5"
                      icon="check"
                      style="color: green"
                    ></b-icon
                  ></b-col>
                  <b-col cols="11"
                    >Sendungsnummer und Sendungsverfolgung automatisch in
                    Auftrag oder Rechnung einfügen</b-col
                  >
                </b-row>
                <b-row>
                  <b-col style="max-width: 2em" cols="1" class="p-0"
                    ><b-icon
                      font-scale="1.5"
                      icon="check"
                      style="color: green"
                    ></b-icon
                  ></b-col>
                  <b-col cols="11"
                    >Sendungsnummer und Sendungsverfolgung automatisch in ERP
                    hinterlegen</b-col
                  >
                </b-row>
                <b-row>
                  <b-col style="max-width: 2em" cols="1" class="p-0"
                    ><b-icon
                      font-scale="1.5"
                      icon="check"
                      style="color: green"
                    ></b-icon
                  ></b-col>
                  <b-col cols="11"
                    >Sämtliche Schweizer Post Dienst- und
                    Zusatzleistungen</b-col
                  >
                </b-row>
              </b-container>
              <br />
              <p class="month">Zusatz <small>(optional je 100 CHF</small>)</p>
              <b-container>
                <b-row>
                  <b-col style="max-width: 2em" cols="1" class="p-0"
                    ><b-icon font-scale="1.5" icon="plus"></b-icon
                  ></b-col>
                  <b-col cols="11">Schulung</b-col>
                </b-row>
                <b-row>
                  <b-col style="max-width: 2em" cols="1" class="p-0"
                    ><b-icon font-scale="1.5" icon="plus"></b-icon
                  ></b-col>
                  <b-col cols="11">Einrichtung</b-col>
                </b-row>
              </b-container>
            </b-card>
          </b-col>
          <b-col cols="12" sm="6" md="6" lg="3" class="pb-3">
            <b-card
              class="h-100"
              style="box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2)"
            >
              <b-card-title class="text-center pb-1">Pro+</b-card-title>
              <h6 class="text-center month">Für grosse Teams</h6>
              <hr />
              <div style="height: 140px">
                <p class="text-center">
                  nur <span class="price">CHF 28.40</span
                  ><span class="month">/Monat¹</span>
                </p>
                <p class="priceExplanation text-center">
                  bei jährlicher Rechnung <br />(CHF 29.90/Monat bei monatlicher
                  Rechnung)
                </p>
              </div>
              <hr />
              <p class="text-center"><b>5 Benutzer</b></p>
              <p class="text-center">
                <b><span class="price">500</span> Labels pro Monat</b>
              </p>
              <b-container>
                <b-row>
                  <b-col style="max-width: 2em" cols="1" class="p-0"
                    ><b-icon
                      font-scale="1.5"
                      icon="check"
                      style="color: green"
                    ></b-icon
                  ></b-col>
                  <b-col cols="11">Rücksendelabels (GAS)</b-col>
                </b-row>
                <b-row>
                  <b-col style="max-width: 2em" cols="1" class="p-0"
                    ><b-icon
                      font-scale="1.5"
                      icon="check"
                      style="color: green"
                    ></b-icon
                  ></b-col>
                  <b-col cols="11"
                    >Versandlabels automatisch auf Auftrag/Rechnung PDF</b-col
                  >
                </b-row>
                <b-row>
                  <b-col style="max-width: 2em" cols="1" class="p-0"
                    ><b-icon
                      font-scale="1.5"
                      icon="check"
                      style="color: green"
                    ></b-icon
                  ></b-col>
                  <b-col cols="11"
                    >Rücksendelabel automatisch auf eigenes PDF pro
                    Sprache</b-col
                  >
                </b-row>
                <b-row>
                  <b-col style="max-width: 2em" cols="1" class="p-0"
                    ><b-icon
                      font-scale="1.5"
                      icon="check"
                      style="color: green"
                    ></b-icon
                  ></b-col>
                  <b-col cols="11"
                    >Sendungsnummer und Sendungsverfolgung automatisch in
                    Auftrag oder Rechnung einfügen</b-col
                  >
                </b-row>
                <b-row>
                  <b-col style="max-width: 2em" cols="1" class="p-0"
                    ><b-icon
                      font-scale="1.5"
                      icon="check"
                      style="color: green"
                    ></b-icon
                  ></b-col>
                  <b-col cols="11"
                    >Sendungsnummer und Sendungsverfolgung automatisch in ERP
                    hinterlegen</b-col
                  >
                </b-row>
                <b-row>
                  <b-col style="max-width: 2em" cols="1" class="p-0"
                    ><b-icon
                      font-scale="1.5"
                      icon="check"
                      style="color: green"
                    ></b-icon
                  ></b-col>
                  <b-col cols="11"
                    >Sämtliche Schweizer Post Dienst- und
                    Zusatzleistungen</b-col
                  >
                </b-row>
              </b-container>
              <br />
              <p class="month">Zusatz <small>(optional je 100 CHF</small>)</p>
              <b-container>
                <b-row>
                  <b-col style="max-width: 2em" cols="1" class="p-0"
                    ><b-icon font-scale="1.5" icon="plus"></b-icon
                  ></b-col>
                  <b-col cols="11">Schulung</b-col>
                </b-row>
                <b-row>
                  <b-col style="max-width: 2em" cols="1" class="p-0"
                    ><b-icon font-scale="1.5" icon="plus"></b-icon
                  ></b-col>
                  <b-col cols="11">Einrichtung</b-col>
                </b-row>
              </b-container>
            </b-card>
          </b-col>
        </b-row>
      </b-container>
      <p class="text-center backgroundSPL m-0" style="font-size: xx-large">
        ...noch Fragen oder kein passendes Abo gefunden?<br />
        <a href="mailto:sales@inn-solutions.ch" style="color: black"
          >Gerne können wir das gemeinsam anschauen!</a
        >
      </p>
    </div>
    <div>
      <div>
        <svg
          style="width: 100%; transform: rotate(180deg)"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 144.54 17.34"
          preserveAspectRatio="none"
          fill="#fcde58"
        >
          <path
            style="border-bottom: 10px solid #fcde58"
            d="M144.54 17.34H0V0H144.54ZM0 0S34 6 72 6 144.54 0 144.54 0"
          ></path>
        </svg>
      </div>
    </div>
    <br />
    <div class="mx-auto pt-4">
      <h3 ref="integrationen" class="text-center">Integrationen</h3>
      <br /><br />
      <b-container style="max-width: 850px" class="">
        <b-row class="w-100 mx-auto">
          <b-col>
            <b-container>
              <b-row>
                <b-col
                  id="bexioCard"
                  class="text-center"
                  style="background-color: #0d2f3b; min-height: 200px"
                >
                  <div style="height: 35%"></div>
                  <svg
                    height="43px"
                    width="88px"
                    class="fill-current"
                    viewBox="0 0 119.1 39.7"
                  >
                    <path
                      d="M71.4 24.9l7.4-10c.4-.6.4-1.4 0-2-.3-.4-.8-.6-1.6-.6h-1.4c-.6 0-1.2.3-1.5.8L68.5 21l-5.7-7.8c-.4-.6-1.1-.9-1.9-.9h-1.1c-.7 0-1.2.2-1.6.6-.5.6-.5 1.4 0 2l7.4 10-8.2 11.2c-.4.6-.4 1.4.1 2 .3.4.8.6 1.3.6h1.7c.5 0 1-.3 1.3-.7l6.7-9.2 6.7 9.1c.4.5.9.8 1.5.8h1.7c.6 0 1.2-.3 1.5-.9.3-.6.2-1.2-.1-1.7l-8.4-11.2zm14-12.6h-.3c-1.3 0-2.2.9-2.2 2v22.3c0 1.1.9 2 2 2h.7c1.1 0 2-.9 2-2V14.3c.1-1.2-.9-2-2.2-2zm28.7 3.4c-2.6-2.6-5.9-4-9.6-4-3.8 0-7 1.3-9.6 4-2.6 2.6-3.9 5.9-3.9 9.7 0 3.8 1.3 7.1 3.9 9.7 2.6 2.6 5.8 4 9.6 4s7-1.3 9.6-4c2.6-2.6 3.9-5.9 3.9-9.7.1-3.8-1.2-7.1-3.9-9.7zM98.4 19c1.7-1.8 3.7-2.6 6.2-2.6s4.5.8 6.2 2.6c1.7 1.8 2.5 3.9 2.5 6.4 0 2.6-.8 4.7-2.5 6.4-1.7 1.7-3.7 2.6-6.2 2.6s-4.5-.8-6.1-2.6c-1.7-1.7-2.5-3.9-2.5-6.5-.1-2.5.7-4.5 2.4-6.3zM56 26.4c.5-.6.8-1.3.7-2.1-.2-3.3-1.6-6.2-3.9-8.5-2.6-2.6-5.9-4-9.6-4-3.8 0-7 1.3-9.6 4-2.6 2.6-3.9 5.9-3.9 9.7 0 3.8 1.3 7.1 3.9 9.7 2.6 2.6 5.8 4 9.6 4 3.7 0 7-1.3 9.6-4 1-1 1.8-2.3 2.4-3.3.2-.4.2-.9 0-1.3-.3-.4-.7-.7-1.3-.7h-1.7c-1 0-1.4.2-1.6.6-.3.5-.7 1-1.1 1.4-1.7 1.7-3.7 2.6-6.2 2.6s-4.5-.8-6.1-2.6c-1.3-1.3-2.1-2.9-2.3-4.7H54c.8.1 1.5-.3 2-.8zm-12.7-9.9c2.5 0 4.5.8 6.2 2.6 1.1 1.1 1.8 2.3 2.1 3.6H35c.4-1.3 1.1-2.5 2.1-3.6 1.7-1.8 3.7-2.6 6.2-2.6zm-29.4-4.7c-3.3 0-6.3 1.1-8.7 3.2V2.9C5.2 1.8 4.3 1 3 1h-.3C1.4 1 .5 1.8.5 2.9v22.6c0 3.8 1.3 7.1 3.9 9.7 2.6 2.6 5.8 4 9.6 4s7-1.3 9.6-4c2.6-2.6 3.9-5.9 3.9-9.7s-1.3-7.1-3.9-9.7c-2.7-2.7-5.9-4-9.7-4zm0 22.7c-2.5 0-4.5-.8-6.1-2.6-1.7-1.7-2.5-3.9-2.5-6.5 0-2.5.8-4.6 2.4-6.4 1.7-1.7 3.7-2.6 6.2-2.6s4.5.8 6.2 2.6c1.7 1.8 2.5 3.9 2.5 6.4 0 2.6-.8 4.7-2.5 6.4-1.7 1.8-3.7 2.7-6.2 2.7zM85.3 1.4c-1.8 0-3.3 1.5-3.3 3.3S83.5 8 85.3 8s3.3-1.5 3.3-3.3-1.5-3.3-3.3-3.3z"
                      fill="#bbdc00"
                    ></path>
                  </svg>
                </b-col>
                <b-col>
                  <div class="text-center h-100">
                    <div
                      style="height: 35%; color: #0d2f3b; font-size: xx-large"
                    >
                      bexio
                    </div>
                    <p style="color: #0d2f3b">
                      Besser im Business mit der bexio Integration!
                    </p>
                    <b-button
                      style="
                        color: #0d2f3b;
                        background-color: #bbdc00;
                        border: 0px none;
                      "
                      >Zur Integration</b-button
                    >
                  </div>
                </b-col>
              </b-row>
            </b-container>
          </b-col>
        </b-row>
      </b-container>
    </div>

    <!-- Footer -->
    <br /><br /><br />
    <div>
      <div>
        <svg
          style="width: 100%"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 144.54 17.34"
          preserveAspectRatio="none"
          fill="#fcde58"
        >
          <path
            style="border-bottom: 10px solid #fcde58"
            d="M144.54 17.34H0V0H144.54ZM0 0S34 6 72 6 144.54 0 144.54 0"
          ></path>
        </svg>
      </div>
    </div>
    <footer class="backgroundSPL pt-4">
      <b-container class="w-100 pt-2" fluid>
        <b-row>
          <b-col cols="2" class="p-0"></b-col>
          <b-col cols="5">
            <h6 class="text-muted">Sitemap</h6>
            <ul class="list-unstyled mb-0">
              <li class="mb-2" style="cursor: pointer">
                <a class="text-muted" @click="scrollMeTo('vorteile', true)"
                  >Vorteile</a
                >
              </li>
              <li class="mb-2" style="cursor: pointer">
                <a class="text-muted" @click="scrollMeTo('gallerie', true)"
                  >Gallerie</a
                >
              </li>
              <li class="mb-2" style="cursor: pointer">
                <a class="text-muted" @click="scrollMeTo('preise', true)"
                  >Preise</a
                >
              </li>
              <li class="mb-2" style="cursor: pointer">
                <a class="text-muted" @click="scrollMeTo('integrationen', true)"
                  >Integrationen</a
                >
              </li>
            </ul></b-col
          >
          <b-col cols="5" class="p-0">
            <h6 class="text-muted">Unternehmen</h6>
            <ul class="list-unstyled">
              <li class="mb-2">
                <a class="text-muted" href="mailto:contact@inn-solutions.ch"
                  >Kontakt</a
                >
              </li>
              <li class="mb-2">
                <p href="#" class="text-muted">
                  Innamorato Solutions <br />Lenzhardweg 5 <br />5702 Niederlenz
                </p>
              </li>
            </ul></b-col
          >
        </b-row>
      </b-container>
      <!-- Copyrights -->
      <div class="py-4" style="background-color: #fcde58">
        <div class="container text-center">
          <p class="mb-0">© 2022 Innamorato Solutions All rights reserved.</p>
        </div>
      </div>
    </footer>
  </div>
</template>

<script>
export default {
  name: "App",
  components: {},
  data() {
    return {
      currentIndex: 0,
      bexioCard: 10,
      mobileMenu: false,
      expanded: false,
      modal: false,
      slides: [
        {
          image: "Slide1.png",
        },
        {
          image: "Slide2.png",
        },
        {
          image: "Slide3.png",
        },
        {
          image: "Slide4.png",
        },
      ],
    };
  },
  created() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },
  mounted() {
    this.startSlide();
  },
  methods: {
    showModal() {
      this.modal = true;
    },
    currentImg: function () {
      return this.getImage(
        this.slides[Math.abs(this.currentIndex) % this.slides.length].image
      );
    },
    startSlide: function () {
      for (let i = 0; i < this.slides.length; i++) {
        setTimeout(this.next, 500);
      }
    },

    next: function () {
      this.currentIndex += 1;
    },
    prev: function () {
      this.currentIndex -= 1;
    },
    getImage(name) {
      return require(`./assets/slides/${name}`);
    },
    handleResize() {
      this.mobileMenu = window.innerWidth > 1200 ? false : true;
    },
    scrollMeTo(refName, closeMobileNav = false) {
      if (closeMobileNav) {
        setTimeout(() => {
          this.expanded = false;
        }, 850);
      }
      var element = this.$refs[refName];
      var top = element.offsetTop - 100;
      window.scrollTo(0, top);
    },
  },
};
</script>

<style>
.price {
  font-size: x-large;
}
.priceExplanation {
  font-size: 0.875rem;
  color: rgba(123, 141, 148, 1);
}
.month {
  color: rgba(123, 141, 148, 1);
}
.modal-fullscreen .modal-dialog {
  max-width: 100%;
  margin: 0;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100vh;
  display: flex;
  position: fixed;
  z-index: 100000;
  background-color: none;
}

.modalBody #modalImg___BV_modal_content_ .modal-content {
  background-color: rgba(0, 0, 0, 0.5) !important;
}
.brandSPL {
  font-weight: bold;
  font-size: larger;
  color: black !important;
}
.navbarSPL {
  width: 100%;
  padding-top: 1.5rem !important;
  padding-bottom: 1rem !important;
  background-color: #fcde58;
}
.btnSPL {
  background-color: #f4b953 !important;
  color: black !important;
  border: 0px !important;
  font-weight: bold !important;
  max-height: 10vw;
}
.btnSPL2 {
  background-color: #f4b953 !important;
  color: black !important;
  border: 0px !important;
  font-weight: bold !important;
}
.toggleSPL {
  background-color: #f4b953 !important;
  color: black !important;
  border: 0px !important;
  font-weight: bold !important;
}
.backgroundSPL {
  background-color: #fcde58 !important;
}

.prev,
.next {
  cursor: pointer;
  top: 40%;
  width: auto;
  padding: 16px;
  color: #f4b953;
  font-weight: bold;
  font-size: 18px;
  transition: 0.7s ease;
  border-radius: 0 4px 4px 0;
  text-decoration: none;
  user-select: none;
}

.next {
  right: 0;
}

.prev {
  left: 0;
}
</style>
